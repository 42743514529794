import { createTheme, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext } from '../auth-context/auth-context';
import { DeploymentPage } from '../components/deployment/deployment';
import { FeatureBranchesPage } from '../components/feature-branch/feature-branch';
import { Main } from '../components/main/main';
import { NavBar } from '../components/nav-bar/nav-bar';
import { ProjectsPage } from '../components/projects/projects';
import { ServicePage } from '../components/service/service';
import { Unauthorized } from '../components/unauthorized/unauthorized';
import { getCookie, parseJWT, putCookie } from '../helpers/cookie.helper';
import './app.scss';
import { EUserPermissions } from './enums';

export default function App() {
  const [userPermissions, setUserPermissions] = useState<EUserPermissions[]>([]);

  useEffect(() => {
    const raw_jwt = getCookie('JWT');
    const jwt = parseJWT(raw_jwt);

    if (!jwt) {
      putCookie('redirect_to', window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: '/',
      });
      window.location.href = process.env.REACT_APP_AUTH_PATH!;
      return;
    } else if (jwt.permissions?.length) {
      setUserPermissions([EUserPermissions.USERS, ...jwt.permissions]);
    }
  }, []);

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ permissions: userPermissions }}>
        <BrowserRouter>
          <main className="main">
            <div className="main__wrapper">
              <div className="main__nav-bar">
                <NavBar />
              </div>

              <div className="main__content">
                <div className="main__container">
                  <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route path="/projects/:project_id" element={<DeploymentPage />} />
                    <Route path="/projects/:project_id/:deployment_id" element={<ServicePage />} />
                    <Route path="/feature-branches" element={<FeatureBranchesPage />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                  </Routes>
                </div>
              </div>
            </div>
          </main>
        </BrowserRouter>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}
