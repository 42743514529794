import { BubbleChart, People, Widgets } from '@mui/icons-material';
import { FC, memo, ReactElement, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EUserPermissions } from '../../app/enums';
import { ReactComponent as IconLogo } from '../../assets/icons/icon-logo.svg';
import { AuthContext } from '../../auth-context/auth-context';
import './nav-bar.scss';

interface IItems {
  permission: EUserPermissions;
  title: string;
  link: string;
  icon: ReactElement;
}

interface INavBarProps {
  permissions?: string[];
}

export const NavBar: FC<INavBarProps> = memo(() => {
  const { permissions } = useContext(AuthContext);
  const items: IItems[] = useMemo(
    () => [
      {
        permission: EUserPermissions.PROJECTS,
        title: 'Projects',
        link: '/projects',
        icon: <Widgets />,
      },
      {
        permission: EUserPermissions.USERS,
        title: 'Users',
        link: '/users',
        icon: <People />,
      },
      {
        permission: EUserPermissions.FEATURE_BRANCHES,
        title: 'Feature branches',
        link: '/feature-branches',
        icon: <BubbleChart />,
      },
    ],
    []
  );

  return (
    <div className="nav-bar">
      <IconLogo className="nav-bar__avatar" />
      <h2 className="nav-bar__title">K8s utils</h2>
      <ul className="nav-bar__list">
        {items.map(
          (item) =>
            permissions &&
            permissions.includes(item.permission) && (
              <li className="nav-bar__item" key={item.title}>
                <Link to={item.link} className="nav-bar__link">
                  <button className="nav-bar__button">{item.icon}</button>
                  {item.title}
                </Link>
              </li>
            )
        )}
      </ul>
    </div>
  );
});
