import { ILaunchInfo } from '../../interfaces';

export enum EFeatureBranchStatus {
  RUNNING = 'running',
  STARTING = 'starting',
  STOPPED = 'stopped',
  STOPPING = 'stopping',
  PENDING = 'pending',
}

export interface EBranchStatusData {
  name: string;
  status: EFeatureBranchStatus;
  launch_info?: ILaunchInfo;
  pods: {
    target: number;
    available: number;
    started: number;
  };
}
