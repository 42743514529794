import { InfoOutlined } from '@mui/icons-material';
import { Switch } from '@mui/material';
import { useContext, useState } from 'react';
import { AccordionWrapper } from '../accordion-wrapper/accordion-wrapper';
import { MyTextField } from '../my-text-field/my-text-field';
import { ServiceContext } from '../service/service-context';

export const MyTextFieldSwitch = () => {
  const service = useContext(ServiceContext).editingService;
  const [isChecked, setIsChecked] = useState(service.config.resources.enabled);

  return (
    <div className="service-config__accordion">
      <Switch
        checked={isChecked}
        color="primary"
        onChange={(_, checked) => {
          service.config.resources.enabled = checked;
          setIsChecked(service.config.resources.enabled);
        }}
        className="service-config__switch"
      />

      <AccordionWrapper title={'Resources limit'} checked={isChecked} defaultChecked={isChecked}>
        <div className="service-config__accordion-item">
          <MyTextField
            label={'CPU limits'}
            defaultValue={service.config.resources.limits.cpu}
            onChangeValue={(value) => (service.config.resources.limits.cpu = value)}
            icon={<InfoOutlined />}
            icon_tooltip={
              'Лимит по cpu, после которого сервис\n является вытекшим и перезапускается\n' +
              'Пример:\n100m (1/10 одного cpu) или 1 (один cpu)\n1000m = 1 cpu. Если указываете целое количество cpu - пишите в цифровом варианте.'
            }
          />
          <MyTextField
            label={'Mem limits'}
            defaultValue={service.config.resources.limits.memory}
            onChangeValue={(value) => (service.config.resources.limits.memory = value)}
            icon={<InfoOutlined />}
            icon_tooltip={
              'Лимит по памяти, после которого сервис является вытекшим и перезапускается\n' +
              'Указывается в байтах, можно указывать следующие суффиксы:\nE, P, T, G, M, K, Ei, Pi, Ti, Gi, Mi, Ki'
            }
          />
          <MyTextField
            label={'CPU requests'}
            defaultValue={service.config.resources.requests.cpu}
            onChangeValue={(value) => (service.config.resources.requests.cpu = value)}
            icon={<InfoOutlined />}
            icon_tooltip={
              'Минимальный cpu, которое выделится для этого сервиса\n' +
              'Пример:\n100m (1/10 одного cpu) или 1 (один cpu)\n1000m = 1 cpu. Если указываете целое количество cpu - пишите в цифровом варианте.'
            }
          />
          <MyTextField
            label={'Mem requests'}
            defaultValue={service.config.resources.requests.memory}
            onChangeValue={(value) => (service.config.resources.requests.memory = value)}
            icon={<InfoOutlined />}
            icon_tooltip={
              'Минимальное количество памяти, которое выделится для этого сервиса\n' +
              'Указывается в байтах, можно указывать следующие суффиксы:\nE, P, T, G, M, K, Ei, Pi, Ti, Gi, Mi, Ki'
            }
          />
        </div>
      </AccordionWrapper>
    </div>
  );
};
