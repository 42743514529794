import { Box, Chip, InputAdornment, Snackbar, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { EBranchStatusData, EFeatureBranchStatus, EUserPermissions } from '../../app/enums';
import { api } from '../../helpers/api.helper';
import { IDeploymentData, ILaunchInfo } from '../../interfaces';

import { MoreVert, Search, Star } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import { withAccess } from '../../auth-context/can-access';
import { DialogChoose } from '../dialog/dialog';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import './feature-branch.scss';

const LocalStorageSearchKey = 'k8s-utils:feature/search';
const LocalStorageFavouriteKey = 'k8s-utils:feature/favourite';

const branch_order = {
  [EFeatureBranchStatus.RUNNING]: 1,
  [EFeatureBranchStatus.STARTING]: 2,
  [EFeatureBranchStatus.PENDING]: 3,
  [EFeatureBranchStatus.STOPPING]: 4,
  [EFeatureBranchStatus.STOPPED]: 5,
} as const;

const COLORS = {
  [EFeatureBranchStatus.RUNNING]: '#00c164',
  [EFeatureBranchStatus.PENDING]: '#439EF4',
  [EFeatureBranchStatus.STOPPED]: '#ff5272',
  [EFeatureBranchStatus.STARTING]: '#439EF4',
  [EFeatureBranchStatus.STOPPING]: '#439EF4',
};

const FeatureBranchesComponent = () => {
  const [featureBranches, setFeatureBranches] = useState<EBranchStatusData[]>([]);
  const [filteredFeatureBranches, setFilteredFeatureBranches] = useState<EBranchStatusData[]>([]);
  const [favouriteBranches, setFavouriteBranches] = useState<Set<string>>(new Set());
  const [searchValue, setSearchValue] = useState('');
  const [changeStatus, setChangeStatus] = useState<{ name: string; status: EFeatureBranchStatus } | null>(null);
  const [toastMessage, setToastMessage] = useState('');

  const toFeatureBranch = (feature_branch: string) => `http://${feature_branch}.feature.trainingspace.online`;

  const getBranchStatus = (name: string, feature_branch: Array<IDeploymentData>, launch_info?: ILaunchInfo) => {
    const branch_services = feature_branch.length;

    const branch_status: EBranchStatusData = {
      name,
      status: EFeatureBranchStatus.RUNNING,
      pods: { available: 0, started: 0, target: 0 },
      launch_info: launch_info,
    };

    const branch_data = feature_branch.reduce(
      (acc, pod_status) => {
        acc.replicas += pod_status.replicas;
        acc.unavailableReplicas += pod_status.unavailableReplicas;
        acc.pods.availeable += pod_status.replicas;
        acc.pods.started += pod_status.availableReplicas;
        acc.pods.target = branch_services;
        return acc;
      },
      {
        replicas: 0,
        unavailableReplicas: 0,
        pods: { availeable: 0, started: 0, target: 0 },
      }
    );

    if (branch_data.replicas === 0 && branch_data.unavailableReplicas === 0) {
      branch_status.status = EFeatureBranchStatus.STOPPED;
    } else if (branch_data.replicas === branch_services && branch_data.pods.started < branch_services) {
      branch_status.status = EFeatureBranchStatus.STARTING;
    } else if (branch_data.replicas === 0 && branch_data.pods.started > 0) {
      branch_status.status = EFeatureBranchStatus.STOPPING;
    } else if (branch_data.replicas !== branch_services && branch_data.unavailableReplicas === 0) {
      branch_status.status = EFeatureBranchStatus.PENDING;
    } else if (branch_data.replicas === branch_services && branch_data.unavailableReplicas !== 0) {
      branch_status.status = EFeatureBranchStatus.PENDING;
    }

    branch_status.pods.available = branch_data.pods.availeable;
    branch_status.pods.started = branch_data.pods.started;
    branch_status.pods.target = branch_data.pods.target;
    return branch_status;
  };

  useEffect(() => {
    const searchHistory = localStorage.getItem(LocalStorageSearchKey);

    if (searchHistory) {
      setSearchValue(searchHistory);
    }

    const getFeatureBranchList = async () => {
      try {
        api.feature_branch.list().then(({ data }) => {
          const feature_branches: EBranchStatusData[] = Object.entries(data).map(([env, feature_branch]) =>
            getBranchStatus(env, feature_branch.deployments, feature_branch.launch_info)
          );

          setFeatureBranches(feature_branches);
        });
      } catch (error) {}
    };

    getFeatureBranchList();

    const favouriteRawData = localStorage.getItem(LocalStorageFavouriteKey);
    let favouriteData = new Set<string>();
    if (favouriteData) {
      const data: string[] = JSON.parse(favouriteRawData);
      favouriteData = new Set(data);
      setFavouriteBranches(favouriteData);
    }

    const interval = setInterval(() => getFeatureBranchList(), 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    localStorage.setItem(LocalStorageSearchKey, searchValue);
  }, [searchValue]);

  useEffect(() => {
    setFilteredFeatureBranches(
      featureBranches
        .filter((branch) => {
          const values = searchValue.split(',');
          return values.some((x) => branch.name.toLowerCase().includes(x.toLowerCase()));
        })
        .sort((a, b) => {
          const favouriteComparator = +favouriteBranches.has(b.name) - +favouriteBranches.has(a.name);
          return favouriteComparator || branch_order[a.status] - branch_order[b.status];
        })
    );
  }, [favouriteBranches, searchValue, featureBranches]);

  const toggleBranch = useCallback(() => {
    const current_branch_status = filteredFeatureBranches.find((branch) => branch.name === changeStatus?.name);
    if (current_branch_status?.status === changeStatus?.status) {
      setToastMessage(
        `Can't change  ${changeStatus?.name} status to ${changeStatus?.status} because it's already ${current_branch_status?.status}`
      );
      setChangeStatus(null);
      return;
    }
    if (changeStatus?.name) {
      if (changeStatus.status === EFeatureBranchStatus.RUNNING) {
        api.feature_branch.changeStatus(changeStatus.name, 'start');
      } else if (changeStatus.status === EFeatureBranchStatus.STOPPED) {
        api.feature_branch.changeStatus(changeStatus.name, 'stop');
      }
    }

    setChangeStatus(null);
  }, [changeStatus]);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value.replace(/\s+/g, ''));

  const closeToast = useCallback(() => setToastMessage(''), []);

  const closeDialog = useCallback(() => setChangeStatus(null), []);

  const addToFavourite = (branch: EBranchStatusData) => {
    setFavouriteBranches((prev) => {
      const newData = new Set([...prev, branch.name]);
      localStorage.setItem(LocalStorageFavouriteKey, JSON.stringify(Array.from(newData)));
      return newData;
    });
  };

  const removeFromFavourite = (branch: EBranchStatusData) => {
    setFavouriteBranches((prev) => {
      const newData = new Set([...prev]);
      newData.delete(branch.name);
      localStorage.setItem(LocalStorageFavouriteKey, JSON.stringify(Array.from(newData)));
      return newData;
    });
  };

  return (
    <>
      <Box className="feature-branch__header">
        <h2 className="header__title">Feature branches</h2>
        <TextField
          className="feature-branch__search"
          label="Search"
          onChange={onChangeInput}
          variant="outlined"
          autoFocus
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {!!filteredFeatureBranches.length && (
        <Box className="box">
          <ul className="box__list">
            {filteredFeatureBranches.map((branch) => (
              <li key={branch.name} className="box__item feature-branch__item">
                <a
                  href={toFeatureBranch(branch.name)}
                  className="box__link feature-branch__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {branch.name}
                  {branch.launch_info ? ` (${branch.launch_info.email} - ${branch.launch_info.up_time})` : ''}
                </a>
                <Box className="feature-branch__info">
                  <Tooltip
                    title={favouriteBranches.has(branch.name) ? 'Удалить из избранного' : 'Добавить в избранное'}
                  >
                    <Star
                      color="info"
                      className={`feature-branch__item-star ${
                        favouriteBranches.has(branch.name) ? 'feature-branch__item-star--active' : ''
                      }`}
                      onClick={() =>
                        favouriteBranches.has(branch.name) ? removeFromFavourite(branch) : addToFavourite(branch)
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`Services running ${branch.pods.started} / ${branch.pods.target}`}>
                    <Chip
                      style={{ backgroundColor: COLORS[branch.status] }}
                      className="box__chip"
                      label={
                        branch.status === EFeatureBranchStatus.STOPPED
                          ? branch.status
                          : `${branch.status} ${branch.pods.started} / ${branch.pods.target}`
                      }
                    />
                  </Tooltip>
                  <DropdownMenu
                    buttonElement={<MoreVert />}
                    items={[
                      {
                        text: branch.status === EFeatureBranchStatus.STOPPED ? 'Запустить' : 'Остановить',
                        onClick: () => {
                          setChangeStatus({
                            name: branch.name,
                            status:
                              branch?.status === EFeatureBranchStatus.STOPPED
                                ? EFeatureBranchStatus.RUNNING
                                : EFeatureBranchStatus.STOPPED,
                          });
                        },
                      },
                    ]}
                  />
                </Box>
              </li>
            ))}
            <DialogChoose
              header={
                changeStatus?.status === EFeatureBranchStatus.STOPPED
                  ? 'Остановить feature branch?'
                  : 'Запустить feature branch?'
              }
              onClose={closeDialog}
              open={!!changeStatus}
              onSubmit={toggleBranch}
              textClose={'Нет'}
              textOk={'Да'}
            >
              <p>{changeStatus?.name}</p>
            </DialogChoose>
          </ul>
        </Box>
      )}
      <Snackbar
        className="toast"
        onClose={closeToast}
        open={!!toastMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeToast} severity="warning">
          <p className="feature-branch__toast">{toastMessage}</p>
        </Alert>
      </Snackbar>
    </>
  );
};

export const FeatureBranchesPage = withAccess(EUserPermissions.FEATURE_BRANCHES)(FeatureBranchesComponent);
